<template>
  <div class="applicant-expertise-container">
    <Loading v-if="isLoading" />
    <div v-if="!isLoading">
      <div>
        <h1 class="applicant-expertise__tittle">Expertise selection</h1>
        <h3 class="applicant-expertise__sub-tittle">
          Relevant experience can help your profile stand out. First, choose the
          expertise to continue with the specialization and its respective
          area/s.
        </h3>
        <div class="applicant-expertise__form">
          <div class="row">
            <div class="column">
              <label class="input-label"><b>Expertise*</b></label>
              <Dropdown
                class="select"
                v-model="expertiseSelected"
                :options="expertises"
                optionLabel="name"
                @change="expertiseChanged()"
                placeholder="Select an expertise"
              />
            </div>
            <div class="column">
              <label for="specialization" class="input-label"
                ><b>Specialization*</b></label
              >
              <MultiSelect
                class="select"
                v-model="specializationsSelected"
                :options="specializations"
                optionLabel="name"
                placeholder="Select specializations"
              />
            </div>
          </div>

          <div
            v-for="specialization of specializationsSelected"
            :key="specialization.id"
            class="card"
          >
            <div class="card_header">
              <div class="specializations-header">
                <h2 class="specializations-header_tittle">
                  {{ specialization.name }}
                </h2>
                <div class="specializations-header_btn">
                  <i
                    class="pi pi-times"
                    @click="removeSpecialization(specialization)"
                  ></i>
                </div>
              </div>
            </div>
            <div class="card_body">
              <ul class="areas-list">
                <li v-for="area in specialization.areas" :key="area.name">
                  {{ area.selectedArea.name + "  -  " }}
                  <strong>{{ area.yearsExperience }}</strong>
                  {{ " Years of experience" }}
                </li>
              </ul>
              <div class="add-area">
                <button class="add-area_btn" @click="openModal(specialization)">
                  <i class="pi pi-plus"></i>
                  <span style="margin-right: 10px">{{ " Add area " }}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <Dialog
          :header="addingAreaSpecialization.name + ' specialization area'"
          :visible.sync="modalDisplayed"
          :containerStyle="{ width: '50vw', height: '80vh' }"
          :modal="true"
          :closable="true"
          :closeOnEscape="true"
        >
          <hr />
          <div class="row">
            <div class="column">
              <label class="input-label"><b>Area*</b></label>
              <Dropdown
                class="select"
                v-model="modalArea.selectedArea"
                :options="areas"
                optionLabel="name"
                placeholder="Select an area"
              />
            </div>
            <div class="column">
              <label class="input-label">Years of experience*</label>
              <input
                class="input"
                type="number"
                id="quantity"
                name="quantity"
                min="1"
                max="5"
                v-model="modalArea.yearsExperience"
              />
            </div>
          </div>
          <div class="form-box">
            <label class="input-label">Software*</label>
            <Chips
              class="select"
              v-model="modalArea.software"
              separator=","
              :max="4"
              :addOnBlur="true"
            />
          </div>
          <div class="row">
            <div class="column">
              <label class="input-label">Sector*</label>
              <Dropdown
                class="select"
                v-model="selectedSector"
                :options="sectors"
                optionLabel="name"
                placeholder="Select sector"
              />
            </div>
            <div class="column">
              <input
                class="input"
                :disabled="selectedSector.name !== 'Other'"
                v-model="inputSector"
                placeholder="Other sector"
              />
            </div>
          </div>
          <div class="form-box">
            <label class="input-label">Level*</label>
            <Dropdown
              class="select"
              v-model="modalArea.selectedLevel"
              :options="levels"
              optionLabel="name"
              placeholder="Select level"
            />
          </div>
          <div class="footer-button">
            <button
              id="save"
              class="primary-button"
              :disabled="
                !modalArea.yearsExperience.length ||
                !selectedSector.id ||
                (selectedSector.name === 'Other' && !inputSector.length) ||
                !modalArea.selectedArea.id ||
                !modalArea.selectedLevel.id
              "
              @click="saveArea()"
            >
              Save
            </button>
          </div>
        </Dialog>
      </div>
      <footerBotons
        class="footer-container"
        @clickOnBack="onBackClick()"
        @clickOnContinue="onContinueClick()"
        :isEnable="isEnable"
      />
    </div>
  </div>
</template>

<script>
import FooterBotons from "../ContactData/Components/FooterBotons.vue";
import Loading from "../../components/Loading.vue";

export default {
  components: { FooterBotons, Loading },
  name: "ApplicantEducation",
  data() {
    return {
      isEnable: false,
      isLoading: false,
      expertises: [],
      expertiseSelected: {
        name: "ingenieria",
        id: 1,
      },

      specializations: [],
      specializationsSelected: [],

      areas: [],

      levels: [],

      selectedSector: "",
      inputSector: "",
      sectors: [],

      // Starting modal fields
      modalDisplayed: false,

      modalArea: {
        selectedArea: [],
        yearsExperience: "",
        software: [],
        sector: "",
        selectedLevel: {},
      },

      addingAreaSpecialization: { name: "" },
    };
  },
  watch: {
    specializationsSelected() {
      this.validateCompleteAreas();
    },
  },

  async mounted() {
    this.getExpertise();
  },

  methods: {
    updateLocalStorageInfo(submitInfo) {
      // Retrieve freelancer information from local storage and update "freelancer" field
      const newFreelancerData = JSON.parse(
        localStorage.getItem("freelance-registry-information"),
      );
      newFreelancerData.freelancer_experticies = submitInfo;
      localStorage.setItem(
        "freelance-registry-information",
        JSON.stringify(newFreelancerData),
      );
    },

    updateLocalStorageProfessionId() {
      const newFreelancerData = JSON.parse(
        localStorage.getItem("freelance-registry-information"),
      );
      newFreelancerData.freelancer.profession_id = this.expertiseSelected.id;
      localStorage.setItem(
        "freelance-registry-information",
        JSON.stringify(newFreelancerData),
      );
    },

    generateExpertiseJson() {
      const freelancerSpecializations = [];

      for (const specialization of this.specializationsSelected) {
        const areas = [];
        for (const area of specialization.areas) {
          areas.push({
            area_id: area.selectedArea.id,
            area_name: area.selectedArea.name,
            level_id: area.selectedLevel.id,
            level_name: area.selectedLevel.name,
            experience: area.yearsExperience,
            sector: area.sector,
            softwares: area.software,
          });
        }
        freelancerSpecializations.push({
          specialization_id: specialization.id,
          specialization_name: specialization.name,
          subcategory_experticies: areas,
        });
      }

      return freelancerSpecializations;
    },

    onContinueClick() {
      this.isLoading = true;
      this.updateLocalStorageProfessionId();
      const parsedExpertise = this.generateExpertiseJson();
      this.updateLocalStorageInfo(parsedExpertise);
      this.$router.push({ path: "/confirm-data" });
      this.isLoading = false;
    },

    onBackClick() {
      this.$router.push({ path: "/applicant-education" });
    },

    async getExpertise() {
      const response = await this.$store.dispatch("freelancer/getProfessions");
      if (!response.length) {
        this.$toast.add({
          severity: "error",
          summary: response,
          detail: "Failed to get professions",
          life: 4000,
        });
      } else {
        this.expertises = response;
      }
    },

    async getSpecializations() {
      this.specializations = await this.$store.dispatch(
        "freelancer/getSpecializations",
        this.expertiseSelected.id,
      );
    },

    async getAreas(specializationId) {
      this.areas = [];
      this.areas = await this.$store.dispatch(
        "freelancer/getAreas",
        specializationId,
      );
    },

    async getLevels() {
      this.levels = [];
      this.levels = await this.$store.dispatch("freelancer/getLevels");
    },

    async getSectors() {
      this.sectors = [];
      this.sectors = await this.$store.dispatch("freelancer/getSectors");
      const otherId = this.sectors.length + 1;
      this.sectors.push({
        name: "Other",
        id: otherId,
      });
    },

    removeSpecialization(specialToDelete) {
      for (let i = 0; i < this.specializationsSelected.length; i += 1) {
        if (specialToDelete.id === this.specializationsSelected[i].id) {
          this.specializationsSelected.splice(i, 1);
        }
      }
      this.validateCompleteAreas();
    },

    openModal(specialization) {
      this.getLevels();
      this.getSectors();
      this.getAreas(specialization.id);
      this.modalDisplayed = true;
      this.addingAreaSpecialization = specialization;

      this.selectedSector = "";
      this.inputSector = "";
      this.modalArea = {
        selectedArea: [],
        yearsExperience: "",
        software: [],
        sector: "",
        selectedLevel: {},
      };
    },

    closeModal() {
      this.modalDisplayed = false;
      this.addingAreaSpecialization = { name: "" };
    },

    saveArea() {
      if (this.selectedSector.name === "Other") {
        this.modalArea.sector = this.inputSector;
      } else {
        this.modalArea.sector = this.selectedSector.name;
      }
      for (const specialization of this.specializationsSelected) {
        if (specialization.id === this.addingAreaSpecialization.id) {
          if (!specialization.areas) {
            specialization.areas = [];
          }
          specialization.areas.push(this.modalArea);
        }
      }
      this.closeModal();
      this.validateCompleteAreas();
    },

    validateCompleteAreas() {
      let completedData = true;
      for (const specialization of this.specializationsSelected) {
        if (!specialization.areas) {
          completedData = false;
        }
      }
      if (this.specializationsSelected.length === 0) {
        this.isEnable = false;
      } else {
        this.isEnable = completedData;
      }
    },

    expertiseChanged() {
      this.specializationsSelected = [];
      this.getSpecializations();
      this.validateCompleteAreas();
    },
  },
};
</script>

<style lang="scss" scoped>
.applicant-expertise-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.in-line {
  display: inline-flex;
  margin-bottom: 2rem;
  width: 70%;
  align-items: center;
  justify-content: center;
}
.row {
  display: flex;
  margin: 1rem 3rem 2rem;
  align-items: flex-end;
  justify-content: space-between;
  width: 80%;
}
.column {
  width: 48%;
}
.input {
  @include input(100%);
  display: block;
  height: 37px;
  &:disabled {
    background: #eeeeee;
    border-color: rgba(grey, 0.4);
  }
}

.plus-circle {
  background-color: #38828f;
  color: white;
  height: 60px;
  width: 60px;
  padding: 0.5rem 0.8rem;
  margin: 1rem 0.5rem 0;
  border-radius: 50%;
  position: relative;
  top: 1.4rem;
}
.pi-plus {
  color: $secondary-color;
  padding: 0px 5px;
  cursor: pointer;
  font-size: 10px;
  font-weight: 900;
}
.pi-times {
  color: $primary-color;
  cursor: pointer;
}

.add-text {
  margin: 0.3rem 0 1rem 3rem;
}

.input-label {
  margin: 0 1rem 0 0.5rem;
  display: block;
}
.select {
  border-radius: 25px;
  width: 100%;
}

::v-deep .p-chips-multiple-container {
  border-radius: 25px;
  width: 100%;
}

::v-deep .p-dropdown-items-wrapper {
  max-height: 300px !important;
}

.select-areas {
  border-radius: 25px;
  width: 50%;
}
.p-chips-multiple-container {
  border-radius: 25px;
  width: 100%;
}
.primary-button {
  @include primary-button();
}
.secondary-button {
  @include secondary-button();
}

.footer-button {
  display: flex;
  justify-content: center;
  margin: 3rem auto 0 auto;
}
.form-box {
  margin: 1rem 3rem 0;
  width: 80%;
}

hr {
  margin-top: 0;
}

.card {
  border: 1px none #ccc;
  border-radius: $border-radius-md;
  box-shadow: $box-shadow;
  width: 60%;
  background-color: white;
  margin: 0 0 1rem;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  &_body {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}
.applicant-expertise {
  width: 100%;
  max-width: 1500px;
  align-self: center;

  &__logo {
    height: 3rem;
    margin: 2rem;
  }
  &__tittle {
    font-size: 2rem;
    margin: 1rem 6rem 0;
  }
  &__sub-tittle {
    margin: 0 6rem 3rem;
  }
  &__form {
    display: grid;
    justify-items: center;
  }
  &__input {
    @include input(100%);
    min-width: 25rem;
    margin-bottom: 1rem;
  }
  &__input-label {
    margin: 0 0 0 0.5rem;
  }
  &__secondary-button {
    @include secondary-button();
    height: 1.8rem;
    min-height: 1.5rem;
  }
  &__add-button {
    border: dashed 1px rgb(180, 180, 180);
    border-radius: 2%;
    cursor: pointer;
    width: 60%;
    height: 65px;
    margin: 1rem auto 8%;
  }
}
.specializations-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  &_tittle {
    margin: 1rem;
  }
  &_btn {
    margin: 1rem;
  }
}

.add-area {
  margin: 0 1rem 1rem 1rem;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  &_btn {
    background: none;
    color: $secondary-color;
    border: solid 1px $secondary-color;
    border-radius: 25px;
    padding: 2px;
    cursor: pointer;
  }
}

.areas-list {
  margin-top: 0;
}

.footer-container {
  padding: 0 6rem 3rem 6rem;
}

::v-deep .p-dialog-content {
  height: 100%;
}
</style>
